<template>
  <div id="chart">
    <apexchart
      ref="mvi"
      type="bar"
      height="350"
      :options="chartOptions"
      :series="[resp.series[2]]"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "MatrixChart",
  data: function () {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        title: {
          text: "สถานภาพความอุดมสมบูรณ์ของป่าชายเลน (MVI)",
          align: "left",
          margin: 10,
          offsetX: 10,
          style: {
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        colors: ["#175787"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.resp.category,
          title: {
            text: "ช่วงเวลา",
          },
        },
        yaxis: {
          title: {
            text: "ความอุดมสมบูรณ์ของป่าชายเลน",
          },
          decimalsInFloat: 0,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value.toFixed(3);
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: this.findMedian(this.resp.series[2]),
              borderColor: "#2192FF",
              label: {
                borderColor: "#2192FF",
                style: {
                  color: "#fff",
                  background: "#2192FF",
                },
                text: "Median is " + this.findMedian(this.resp.series[2]).toFixed(3),
              },
            },
          ],
        },
      },
    };
  },
  props: {
    resp: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    resp() {
      this.updateChart();
    },
  },
  methods: {
    updateChart() {
      this.$refs.mvi.updateOptions({
        xaxis: {
          categories: this.resp.category, //ie ["a","b","c","d"]
        },
      });

      this.$refs.mvi.updateOptions({
        annotations: {
          yaxis: [
            {
              y: this.findMedian(this.resp.series[2]),
              borderColor: "#2192FF",
              label: {
                borderColor: "#2192FF",
                style: {
                  color: "#fff",
                  background: "#2192FF",
                },
                text: "Median is " + this.findMedian(this.resp.series[2]).toFixed(3),
              },
            },
          ],
        },
      });
    },
    findMedian(value) {
      const filteredData = value.data.filter((num) => num !== 0);
      const sortedData = filteredData.sort();

      const len = sortedData.length;
      return len % 2 === 0
        ? (sortedData[len / 2 - 1] + sortedData[len / 2]) / 2
        : sortedData[Math.floor(len / 2)];
    },
  },
};
</script>
