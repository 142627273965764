<template>
  <v-main>
    <div style="height: 100%">
      <v-card class="d-flex flex-row pa-0" elevation="0" style="height: 100%">
        <v-row class="mt-0 pa-0">
          <v-col
            cols="12"
            sm="5"
            md="5"
            class="py-3 pt-3 pl-6 pr-2"
            style="overflow-y: scroll; height: calc(100vh - 180px)"
          >
            <v-card class="d-flex flex-column pa-0 mb-4" elevation="2">
              <v-card
                class="d-flex justify-start py-6 px-4 rounded-b-0"
                color="green"
                dark
                elevation="0"
              >
                <div class="mr-3">
                  <v-icon large color="white"> mdi-forest </v-icon>
                </div>
                <h2>Mangrove analysis</h2>
              </v-card>
              <template>
                <v-expansion-panels :value="opened">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h2><b>กรุณาเลือกช่วงเวลาและพื้นที่ที่ท่านสนใจ</b></h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- date search -->
                      <p><b>เลือกช่วงเวลา</b></p>
                      <v-row class="mb-6">
                        <v-col cols="12" sm="2" md="2" class="pr-0">
                          เดือน
                          <v-autocomplete
                            v-model="selectedMonthRange.monthStart"
                            :items="month"
                            item-text="name"
                            return-object
                            label="MM"
                            solo
                            hide-details
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          ปี
                          <v-autocomplete
                            v-model="selectedMonthRange.yearStart"
                            :items="yearList"
                            item-text="name"
                            return-object
                            label="YYYY"
                            solo
                            hide-details
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="1"
                          class="d-flex flex-column justify-center align-center pt-8"
                          >ถึง</v-col
                        >
                        <v-col cols="12" sm="2" md="2" class="pr-0">
                          เดือน
                          <v-autocomplete
                            v-model="selectedMonthRange.monthEnd"
                            :items="month"
                            item-text="name"
                            return-object
                            label="MM"
                            solo
                            hide-details
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          ปี
                          <v-autocomplete
                            v-model="selectedMonthRange.yearEnd"
                            :items="yearList"
                            item-text="name"
                            return-object
                            label="YYYY"
                            solo
                            hide-details
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <!-- area search -->
                      <p><b>เลือกพื้นที่ค้นหา</b></p>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          จังหวัดที่ติดชายทะเล (1)
                          <v-autocomplete
                            v-model="provinceSelected"
                            :items="provinceList"
                            item-text="name"
                            return-object
                            label="Province"
                            solo
                            hide-details
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          อำเภอ (2)
                          <v-autocomplete
                            v-model="districtSelected"
                            :items="districtList"
                            item-text="name"
                            label="District"
                            solo
                            return-object
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          ตำบล (3)
                          <v-autocomplete
                            v-model="subDistrictSelected"
                            :items="subDistrictList"
                            item-text="name"
                            label="Subdistrict"
                            solo
                            return-object
                            hide-details
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <!-- btn search -->
                      <div class="d-flex justify-end mt-6">
                        <v-btn
                          @click="createLayerObj"
                          class="mb-4"
                          depressed
                          color="primary"
                          large
                          :disabled="!(provinceSelected.name && isValidDate)"
                          ><v-icon left> mdi-magnify </v-icon> ค้นหา</v-btn
                        >
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <!-- selected -->
              <div class="d-flex flex-column pa-6" v-show="modeType === 'filter'">
                <div>
                  <h2><b>ผลลัพธ์</b></h2>
                  <br />
                  <div v-show="!isResult">กรุณาค้นหาข้อมูล</div>
                  <ChartMangrove v-if="isResult" :resp="resp" />
                  <ChartMvi v-if="isResult" :resp="resp" />
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col
            class="ma-0 pt-0"
            cols="12"
            sm="7"
            md="7"
            style="position: relative; height: calc(100vh - 116px)"
          >
            <longdo-map @load="init" />
            <!-- <v-card v-if="swipeMode" id="map-slider"> swipe mode </v-card> -->
            <v-card id="map-slider">
              <v-slider
                v-model="resultLabel"
                :tick-labels="ticksLabels"
                :max="ticksLabels.length - 1"
                ticks="always"
                tick-size="4"
              ></v-slider>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-main>
</template>
<script>
import provinceList from "@/assets/mangrove/province-marine.json";
import ChartMangrove from "@/components/msp/ChartMangrove.vue";
import ChartMvi from "@/components/msp/ChartMvi.vue";
export default {
  name: "Mangrove",
  data: () => ({
    title: "Mangrove analysis",
    modeType: "filter",
    locactionInit: { lat: 12.899755, lon: 100.491981 },
    provinceList: provinceList,
    isResult: false,
    districtList: [],
    subDistrictList: [],
    provinceSelected: {},
    districtSelected: {},
    subDistrictSelected: {},
    geocodeSelected: {},
    selectedMonthRange: {
      monthStart: "01",
      monthEnd: "03",
      yearStart: "2019",
      yearEnd: "2019",
    },
    map: undefined,
    swipeMode: false,
    resultLabel: 0,
    ticksLabels: [""],
    month: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
    yearList: [
      new Date().getFullYear().toString(),
      (new Date().getFullYear() - 1).toString(),
      (new Date().getFullYear() - 2).toString(),
      (new Date().getFullYear() - 3).toString(),
      (new Date().getFullYear() - 4).toString(),
    ],
    layerArr: {
      mvi: [],
      ndvi: [],
      mangrove: [],
    },
    typeSelected: "MangroveArea",
    geocode: "",
    resp: {},
    opened: 0,
    isShowMVILayer: true,
    isShowNDVILayer: true,
    isShowMangroveLayer: true,
    baseLayersList: [],
  }),
  components: {
    ChartMangrove,
    ChartMvi,
  },
  mounted() {},
  computed: {
    isValidDate() {
      return (
        new Date(this.selectedMonthRange.yearStart, this.selectedMonthRange.monthStart) <
        new Date(this.selectedMonthRange.yearEnd, this.selectedMonthRange.monthEnd)
      );
    },
  },
  methods: {
    init(map) {
      let self = this;
      self.map = map;
      self.map.location(self.locactionInit);
      self.map.zoom(8);

      this.layerList = [];
      this.baseLayersList = [
        { label: "ดาวเทียม (ไทยโชต)", value: window.longdo.Layers.THAICHOTE },
        { label: "ถนน", value: window.longdo.Layers.NORMAL },
        { label: "สีเทา", value: window.longdo.Layers.GRAY },
        { label: "ปกครอง", value: window.longdo.Layers.POLITICAL },
        { label: "Longdo OSM", value: window.longdo.Layers.LONGDO_OSM },
        { label: "<hr />", type: window.longdo.ButtonType.Group },
        { label: "OpenStreetMap", value: window.longdo.Layers.OSM },
        { label: "<hr />", type: window.longdo.ButtonType.Group },
        { label: "Google - ถนน", value: window.longdo.Layers.GOOGLE_ROADMAP },
        { label: "Google - ดาวเทียม", value: window.longdo.Layers.GOOGLE_SATELLITE },
      ];

      this.menuControlList = new window.longdo.MenuBar({
        dropdown: this.baseLayersList,
        dropdownLabel: "เพิ่มเติม",
        change: this.menuChange,
      });

      this.map.Ui.LayerSelector.visible(false);
      this.map.Ui.add(this.menuControlList, window.longdo.UiPosition.Right);
      // window.menuBar = this.menuControlList;

      self.map.Event.bind("overlayLoad", function (overlay) {
        self.map.location({
          lat: overlay[0].linearray[0].centerlat,
          lon: overlay[0].linearray[0].centerlong,
        });
        // console.log(overlay);
      });

      // map.Event.bind("layerChange", function (event) {
      //   console.log(event);
      // });
    },
    menuChange(toItem) {
      // remove old layer
      let self = this;

      // add new layer
      self.map.Layers.clear();
      self.map.Layers.setBase(toItem.value);

      self.map.Layers.add(
        new window.longdo.Layer(
          "mangrove:" +
            self.typeSelected +
            "_" +
            self.ticksLabels[this.resultLabel].split("/")[1] +
            "_" +
            self.ticksLabels[this.resultLabel].split("/")[0],
          {
            type: window.longdo.LayerType.WMTS,
            url: process.env.VUE_APP_GEOSERVER_WMTS,
            srs: "EPSG:3857",
            tileMatrix: (z) => "EPSG:3857:" + z,
          }
        )
      );
    },
    addWMS(layerName) {
      let self = this;

      self.map.Layers.clear();

      // WMTS
      self.map.Layers.add(
        new window.longdo.Layer("mangrove:" + layerName, {
          type: window.longdo.LayerType.WMTS,
          url: process.env.VUE_APP_GEOSERVER_WMTS,
          srs: "EPSG:3857",
          tileMatrix: (z) => "EPSG:3857:" + z,
        })
      );
    },
    createLayerObj() {
      const self = this;
      self.resp = {};
      self.ticksLabels = undefined;
      self.getData(self.geocodeSelected.id); // make graph
      self.drawArea(self.geocodeSelected); // make area

      self.addWMS(
        self.typeSelected +
          "_" +
          self.selectedMonthRange.yearStart +
          "_" +
          self.selectedMonthRange.monthStart
      );

      // self.swipeMode = self.calculateDiff(
      //   new Date(
      //     self.selectedMonthRange.yearStart,
      //     self.selectedMonthRange.monthStart,
      //     "1"
      //   ),
      //   new Date(self.selectedMonthRange.yearEnd, self.selectedMonthRange.monthEnd, "1")
      // );

      self.map.Overlays.clear();
      self.isResult = true;
      this.opened = 1;
    },
    drawArea(geocode) {
      // วาดขอบเขตจังหวัด
      let self = this;
      self.map.Overlays.load(
        new window.longdo.Overlays.Object(geocode.id, "IG", {
          title: geocode.name,
          label: geocode.name,
          lineColor: "#185787",
          lineWidth: 2,
          fillColor: null,
        })
      );
    },
    getData(geocode) {
      let self = this;
      let url =
        // "https://marine-web.longdo.com/services" +
        process.env.VUE_APP_WEBSERVICE +
        `/get/ndvi?start_month=${parseInt(
          self.selectedMonthRange.monthStart
        )}&start_year=${self.selectedMonthRange.yearStart}&end_month=${parseInt(
          self.selectedMonthRange.monthEnd
        )}&end_year=${self.selectedMonthRange.yearEnd}&geocode=${geocode}`;

      self.axios.get(url).then((response) => {
        self.resp = response.data;
        self.ticksLabels = response.data.category;
        self.resultLabel = self.ticksLabels[0];
      });
    },
    monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    calculateDiff(d1, d2) {
      let self = this;
      let diff = self.monthDiff(d1, d2);
      console.log(diff);

      // return (
      //   d1.toISOString().substring(0, 10),
      //   "to",
      //   d2.toISOString().substring(0, 10),
      //   ":",
      //   diff
      // );
    },
  },
  watch: {
    provinceSelected(val) {
      let self = this;
      self.districtList = [];
      self.subDistrictList = [];
      self.geocodeSelected = val;
      let url = process.env.VUE_APP_WEBSERVICE + "/get/district/" + val.id;

      self.axios.get(url).then((response) => {
        self.districtList = response.data;
      });
    },
    districtSelected(val) {
      let self = this;
      self.subDistrictList = [];
      self.geocodeSelected = val;
      if (self.districtSelected.name) {
        let url = process.env.VUE_APP_WEBSERVICE + "/get/subdistrict/" + val.id;
        self.axios.get(url).then((response) => {
          self.subDistrictList = response.data;
        });
      }
    },
    subDistrictSelected(val) {
      let self = this;
      self.geocodeSelected = val;
    },
    resultLabel(val) {
      let self = this;
      if (self.ticksLabels[val]) {
        self.addWMS(
          self.typeSelected +
            "_" +
            self.ticksLabels[val].split("/")[1] +
            "_" +
            self.ticksLabels[val].split("/")[0]
        );
      }
    },
  },
};
</script>
<style scoped>
#map-slider {
  position: absolute;
  width: 90%;
  z-index: 1000;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
}
#map-selected-layer {
  position: absolute;
  z-index: 100;
  width: 150px;
  top: 60px;
  right: 20px;
  padding: 10px;
}
</style>

<style>
.v-application--is-ltr .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
  font-size: 12px !important;
}
</style>
